<template>
	<div>
		<template v-if="!imodel.loaded"><v-progress-linear indeterminate /></template>
		<template v-else>
			<div v-show="!isEdit">
				<Search @filter="filter = $event" :defaults="defaultSearch" />
				<v-toolbar flat>
					<v-toolbar-title>{{ imodel.Title }}</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
				<list
					ref="list"
					headers="id,utility_code,n_utility_session_template_id,date_start,date_end,month,year,izr_sess,locked,price_1_mwh,price_1_mwh_novat"
					:filter="filter2"
					@edit="onEdit"
					hide-delete hide-select
					sort-by="id"
					sort-desc
				/>
			</div>
			<edit-column
				:id="id"
				disabled-fields="utility_code,n_utility_session_template_id,locked,izr_sess,status"
				v-if="isEdit"
				@saveclose="onSaveClose"
				@save="onSave"
				@close="onEditCancel"
				ref="EditForm"
			>
			</edit-column>
		</template>
	</div>
</template>

<script>
import Model from "./Model";
import List from "@/ittijs/List";
import Search from "@/ittijs/Search";
import SearchInput from '@/ittijs/Inputs/SearchInput.vue';
import IndexMixin from "@/ittijs/IndexMixin";
import EditInput from '@/ittijs/Inputs/EditInput.vue';
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
import {cloneDeep} from "lodash";
import AvgTempCalc from "@/views/clients_screen/AvgTempCalc";
import EditColumn from "@/ittijs/EditColumn";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable vue/no-unused-components */

const imodel = ITTIModelProvider.getModel(Model);

export default {

	mixins: [
		IndexMixin,
		SelectedUtilityMixin,
	],

	components: {
		EditColumn,
		AvgTempCalc,
		Search,
		List,
		SearchInput,
		EditInput,
	},

	data(){
		return {
			defaultSearch: {
				rules:[],
				groups:{
					g1:{rules:{}
					}
				},
			},
			filter: {},
			imodel: imodel,
		};
	},

	computed: {
		filter2: function(){
			if (!this.selectedUtility) return this.filter;
			const f2 = cloneDeep(this.filter);
			f2.groups = {
				constrains: {
					rules: {
						utility_code: {
							field: 'utility_code',
							op: 'eq',
							value: this.selectedUtility,
						}
					}
				}
			};
			return f2;
		},
	},

	provide: function () {
		return {
			imodel,
		};
	},

};
</script>